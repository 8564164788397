.step-images {
    width: 5%;
    margin: 1rem 2.5%;
    padding: 0.5rem;
    border: 1px solid #eee;
    background-color: #ffa857;
    border-radius: 50%;
}

img.active {
    background-color: #00ff15d8;
}