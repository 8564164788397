/*===================== 
	Premery bg color 
=====================*/
/* Hex */
.bg-primary,
.meet-ask-row:after,
.dez-separator.style-skew[class*="style-"]:after,
.dez-separator.style-skew[class*="style-"]:before,
.dez-tilte-inner.skew-title:after,
.date-style-2 .post-date,
.date-style-3.skew-date .post-date,
.date-style-3.skew-date .post-date:before,
.overlay-primary-light:after,
.overlay-primary-middle:after,
.overlay-primary-dark:after,
.overlay-primary:after,
.widget-title:after,
.site-button,
.comments-area .comment-form p input[type="submit"],
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus,
.footer-line:after,
.testimonial-1 .quote-left:before,
.testimonial-2 .quote-left:before,
.site-filters .active > [class*="site-button"],
.site-filters li a.active,
.list-num-count > li:before,
.date-style-4 .post-date strong,
.date-style-3 .post-date,
.date-style-2 .post-date,
#myNavbar li.active a,
.skew-angle:after,
.header-style-4.style-1 .header-nav .nav > li.active a:before,
.header-style-4.style-1 .header-nav .nav > li:hover > a:before,
.dez-project-left:after,
.service-carousel .owl-prev, 
.service-carousel .owl-next,
.about-us .dez-tabs .nav-tabs li a.active,
.testimonial-text.bg-primary,
.sidenav ul li a:hover,
.owl-theme.dots-style-2 .owl-dots .owl-dot.active span,
.owl-btn-1.primary .owl-prev, 
.owl-btn-1.primary .owl-next,
.owl-btn-2.primary .owl-prev, 
.owl-btn-2.primary .owl-next,
.owl-slider-banner .owl-theme .owl-dots .owl-dot.active span, 
.owl-slider-banner .owl-theme .owl-dots .owl-dot:hover span,
.product-item-size .btn.active,
.ui-widget-header,
.owl-theme.dots-style-3 .owl-dots .owl-dot.active span, 
.owl-theme.dots-style-3 .owl-dots .owl-dot:hover span,
.owl-item.active.center .testimonial-text,
.icon-bx-wraper.hover:hover,
.portfolio-sep,
.portfolio-ic .dez-img-overlay1:before,
.dez-box-bg.active .icon-box-btn .site-button,
.dez-accordion.primary .acod-head a,
.side-bar .widget_tag_cloud a:hover,
.time-line:after,
.line-right:after,
.line-left:after,
.tabs-site-button .nav-tabs li a.active:focus,
.tabs-site-button .nav-tabs li a.active:hover,
.tabs-site-button .nav-tabs li a.active,
.box-item-service:hover .item-service-content,
.box-item-service:hover h5,
.box-item-service:hover .icon-bx-md,
.tp-caption.rev-btn.tc-btnshadow.tp-rs-menulink,
.la-anim-10,
.owl-dots-primary-full.owl-theme .owl-dots .owl-dot span, 
.owl-dots-primary-big.owl-theme .owl-dots .owl-dot span,
.custom-radio .custom-control-input:checked ~ .custom-control-label:before,
.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before,
.pagination li.active a,
.rev-slider .tparrows,
.job-time a:hover span,
.category-jobs-info .nav ul li.active a,
.slick-slide.slick-active.slick-current + .slick-active .testimonial-text {
 background-color: #2e55fa;
}
/*Hex color :hover */
.site-button:active,
.site-button:hover,
.site-button:focus,
.active > .site-button,
.bg-primary-dark,
.pagination > li > a:hover, 
.pagination > li > span:hover, 
.pagination > li > a:focus, 
.pagination > li > span:focus ,
.pagination > .active > a, 
.pagination > .active > span, 
.pagination > .active > a:hover, 
.pagination > .active > span:hover, 
.pagination > .active > a:focus, 
.pagination > .active > span:focus,
.sidenav .closebtn:hover,
.sidenav .closebtn:focus,
.owl-btn-1.primary .owl-prev:hover, 
.owl-btn-1.primary .owl-next:hover,
.owl-btn-2.primary .owl-prev:hover, 
.owl-btn-2.primary .owl-next:hover{
 background-color: #1d46f5;
}

.about-us .dez-tabs .nav-tabs li a.active {
 border-right: 4px solid #1d46f5;
}


/* Rgba color */
.dez-info-has.bg-primary,
.dez-info-has.bg-secondry:hover,
.tp-bannertimer.tp-bottom{
 background-color: rgba(255, 94, 165, 0.9);
}
.main-slider .tp-shapewrapper.ov-tp {
	background-color: rgba(255, 94, 165, 0.75);
}
/* Box Shadow */
.side-bar .widget_tag_cloud a:hover {
 box-shadow:5px 5px 10px 0 rgba(255, 94, 165, 0.15);
}
.team-section .team-content,
.tp-overlay-video{
	background-color: rgba(255, 94, 165, 0.7);
}
/*===================== 
	Premery text color 
=====================*/
a,
.text-primary,
.primary li:before,
.breadcrumb-row ul li a,
.header-nav .nav > li.active > a,
.header-nav .nav > li.current-menu-item > a .header-nav .nav > li .sub-menu li a:hover,
.header-nav .nav > li:hover > a,
.header-nav .nav > li .mega-menu > li ul a:hover,
.header-nav .nav > li .sub-menu li:hover > a,
.nav-dark.header-nav .nav > li .sub-menu li:hover > a,
.nav-dark.header-nav .nav > li .mega-menu > li ul a:hover,
blockquote:before,
ol.comment-list li.comment .reply a,
footer a:active,
footer a:focus,
footer a:hover,
footer h1 a,
footer h2 a,
footer h3 a,
footer h4 a,
footer h5 a,
footer h6 a,
footer p a,
button.scroltop,
.testimonial-1 .testimonial-position,
.testimonial-4 .testimonial-name:after,
.testimonial-2 .testimonial-position,
.testimonial-3 .testimonial-position,
.testimonial-3 .quote-left:before,
.acod-head a:after,
.acod-head a,
.acod-head a:hover,
.acod-head a.collapsed:hover,
.dez-tabs .nav-tabs > li > a i,
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
.site-button.outline,
.site-button-link:hover,
.item-info-in ul li a:hover,
.dez-post-meta.text-primary i,
.dez-post-meta.text-primary ul li,
.dez-post-meta.text-primary ul li a,
.dez-post-meta i,
.comments-area p:before,
.about-8-service .icon-bx-wraper:hover a,
.about-service .icon-bx-wraper:hover a,
.dez-box-bg .icon-box-btn .site-button,
.list-2 li:hover:before,
.site-filters.style1 li.active a, 
.site-filters.style1.white li.active a,
.site-button-link.black:hover,
.widget_categories ul li a:hover, 
.widget_archive ul li a:hover, 
.widget_meta ul li a:hover, 
.widget_pages ul li a:hover, 
.widget_recent_comments ul li a:hover, 
.widget_nav_menu li a:hover, 
.widget_recent_entries ul li a:hover, 
.widget_services ul li a:hover,
.find-job-bx .input-group-text,
.find-job-bx .bootstrap-select.btn-group .dropdown-toggle .caret::before,
.post-job-bx ul li,
.cv-manager ul li,
.job-time span,
.like-btn,
.testimonial-name,
.job-links i,
.browse-job.applied-job .post-job-bx li a p,
.candidate-info ul li a:hover,
.candidate-info ul li a.active,
.feature,
.dez-post-meta ul li,
.recent-posts-entry .post-date{
 color: #2e55fa;
}
@media only screen and (max-width: 991px) {
 .nav-dark.header-nav .nav > li > a:hover, 
	.nav-dark.header-nav .nav > li > a:active, 
	.nav-dark.header-nav .nav > li > a:focus {
  color: #2e55fa;
 }
}
/*===================== 
	Premery border color 
=====================*/
.navbar-toggle,
.testimonial-4 .testimonial-pic,
.testimonial-4:after,
.testimonial-4 [class*="quote-"],
button.scroltop,
blockquote,
.widget_gallery li:hover,
.owl-slider-banner .owl-theme .owl-dots .owl-dot.active, 
.owl-slider-banner .owl-theme .owl-dots .owl-dot:hover,
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
input[type="checkbox"]:checked + label:before,
input[type="checkbox"] + label:hover:before, 
input[type="radio"] + label:hover:before,
.owl-theme.dots-style-3 .owl-dots .owl-dot.active, 
.owl-theme.dots-style-3 .owl-dots .owl-dot:hover,
.side-bar .widget_tag_cloud a:hover {
	border-color: #2e55fa;
}
.owl-item.active.center .testimonial-8 .testimonial-text:after{
 border-top:15px solid #2e55fa;
}
.dez-tabs.border-top .nav-tabs > li > a.active,
.header-nav .nav > li .sub-menu, 
.header-nav .nav > li .mega-menu {
 border-top-color: #2e55fa;
}
.port-style1 .port-box {
 border-left: 3px solid #2e55fa;
}
.choseus-tabs .nav li a.active {
	border-bottom: 2px solid #2e55fa;
}
.featurd-job-list .job-box-list:hover {
    border-left: 2px solid #2e55fa;
}